:root {
  --alice-blue: #e8f0f2;
  --midnight-blue: #0a2342;
  --tomato: #f0524b;
  --gold: #ffcf40;
}

.body {
  font-family: Lineto circular, sans-serif;
  font-weight: 500;
}

.app, .interface {
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.render {
  width: 100%;
  height: 100%;
  background-color: var(--alice-blue);
}

.nav {
  z-index: 500;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.slide {
  width: 40vw;
  height: 100%;
  min-width: 500px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 120px;
  padding-right: 0;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.slide.feature-slide {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 0 0 130px;
  overflow: scroll;
}

.slide.floor-select {
  width: 300px;
  justify-content: center;
  align-items: flex-end;
  padding-top: 0;
}

.slide.full-width-slide {
  width: 100%;
  padding-top: 0;
  padding-right: 0;
}

.slide.full-width-slide.landing-page-slide {
  height: 100vh;
  flex-flow: column wrap;
  justify-content: flex-start;
  display: block;
  overflow: scroll;
}

.slide.intro-slide {
  width: 100%;
  background-color: var(--midnight-blue);
  padding-right: 0;
}

.viz-info {
  width: 330px;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 25px;
  display: none;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0;
}

.nav-list {
  width: 67%;
  flex: none;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.nav-list.burger-list {
  margin-right: 46px;
  padding-right: 0;
}

.nav-item {
  width: 100%;
  height: 30px;
  color: #f0524b;
  letter-spacing: .3px;
  cursor: pointer;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.nav-item:hover {
  color: #0a2342;
  border-bottom-color: #0a2342;
}

.nav-item.nav-active {
  border-bottom-color: var(--midnight-blue);
  color: var(--midnight-blue);
}

.nav-item.nav-active:hover {
  border-bottom-color: var(--midnight-blue);
}

.nav-item.nav-active.burger-active, .nav-item.burger-active {
  color: #fff;
}

.h1 {
  color: var(--midnight-blue);
  line-height: 41px;
}

.h1.main-header {
  margin-top: 0;
  margin-bottom: 40px;
}

.fifty-text-logo {
  color: var(--tomato);
  letter-spacing: 0;
}

.fifty-text-logo.white-logo {
  color: #fff;
}

.ft-glyph {
  color: var(--tomato);
  letter-spacing: -2px;
}

.slide-panel {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

.slide-panel.panel-red {
  width: 100%;
  padding: 0;
}

.slide-panel.building-panel {
  padding-top: 10px;
  padding-bottom: 0;
}

.copy {
  max-width: 550px;
  color: var(--tomato);
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 500;
  line-height: 26px;
}

.slide-horiz-nav {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.slide-horiz-nav.nav-fall-in {
  width: 60%;
  justify-content: space-between;
}

.slide-horiz-nav.amenity-toggle-nav {
  margin-top: 10px;
}

.slide-horiz-item {
  border-bottom: 1px solid var(--tomato);
  color: var(--midnight-blue);
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 19px;
}

.slide-horiz-item:hover {
  color: var(--tomato);
  border-bottom-color: #fff;
}

.slide-horiz-item.amenity-toggle-item {
  color: var(--midnight-blue);
  border-bottom-style: none;
  margin-bottom: 0;
  font-size: 12px;
}

.slide-popout {
  z-index: 100;
  width: 100%;
  height: 25vw;
  min-height: 390px;
  margin-top: 0;
  margin-left: 0%;
  position: relative;
}

.slide-popout.location-popout {
  height: 340px;
  min-height: 300px;
}

.slide-popout.building-popout {
  height: 40vh;
}

.slide-popout.elizabeth-popout {
  width: 60%;
  height: 20vh;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  bottom: 106px;
  right: 25px;
}

.panel-padding-container {
  z-index: 100;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

.thumb-gallery {
  width: 100%;
  height: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.thumb-gallery-img {
  width: 100%;
  height: 100%;
}

.thumb-gallery-img.img-placeholder {
  background-image: url('../images/img-placeholder.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.enlarge-button {
  width: 55px;
  height: 55px;
  background-color: var(--gold);
  cursor: pointer;
  background-image: url('../images/enlarge-icon.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  margin-left: 0;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.enlarge-button:hover {
  background-color: #fff;
}

.enlarge-button.feature-button {
  margin-left: -55px;
}

.compass {
  width: 35px;
  height: 35px;
  background-image: url('../images/compass.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.instructions-wrapper {
  flex-direction: column;
  align-self: auto;
  align-items: flex-start;
  margin-top: 20px;
  display: flex;
}

.instruction-item {
  width: auto;
  background-color: #0a2342;
  border-radius: 4px;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
  overflow: hidden;
}

.instruction {
  color: #fff;
  background-color: #0a2342;
  border-radius: 3px;
  padding: 10px 10px 10px 35px;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
}

.instruction.orbit-icon {
  background-image: url('../images/rotate-camera-icon.svg');
  background-position: 10px 5px;
  background-repeat: no-repeat;
  border-radius: 4px;
  flex: none;
  align-self: center;
}

.instruction.pan-icon {
  background-image: url('../images/pan-camera-icon.svg');
  background-position: 10px 46%;
  background-repeat: no-repeat;
  flex: 0 auto;
}

.instruction.zoom-icon {
  background-image: url('../images/zoom-camera-icon.svg');
  background-position: 10px 46%;
  background-repeat: no-repeat;
}

.instruction-highlight {
  color: #ffcf40;
}

.instruction-reset-button {
  float: right;
  color: #0a2342;
  cursor: pointer;
  background-color: #fff;
  background-image: url('../images/reset-button-icon.svg');
  background-position: 10px 10px;
  background-repeat: no-repeat;
  border-radius: 4px;
  justify-content: flex-end;
  align-self: flex-start;
  margin-top: 10px;
  padding: 10px 10px 10px 35px;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  display: block;
}

.instruction-reset-button:hover {
  background-color: #eae5d2;
}

.feature-slide-container {
  width: 520px;
  height: 100%;
  background-color: #fff;
  padding-top: 30px;
  padding-left: 75px;
  padding-right: 40px;
  position: relative;
}

.feature-img-block {
  height: 343px;
  cursor: pointer;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  position: relative;
}

.h2 {
  color: var(--tomato);
  margin-bottom: 20px;
}

.h2.feature-vert {
  direction: ltr;
  text-align: right;
  white-space: nowrap;
  flex: none;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: none;
  position: static;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.feature-list {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

.feature-item {
  color: var(--midnight-blue);
  border-bottom: 1px #d8e6f3;
  border-radius: 0 4px 4px 0;
  margin-bottom: 5px;
  padding: 10px 20px 10px 15px;
  font-size: 15px;
}

.slide-close-container {
  z-index: 500;
  width: 55px;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 35px;
  right: auto;
}

.slide-close {
  width: 54px;
  height: 54px;
  background-color: var(--gold);
  cursor: pointer;
  background-image: url('../images/close-icon.svg');
  background-position: 51.5%;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 10000px;
}

.slide-close:hover {
  background-color: #fff;
}

.loader {
  z-index: 1500;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.loader-bg {
  background-color: #fff;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.loader-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loader-group {
  width: 83px;
  height: 58px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.loader-mask {
  z-index: 100;
  width: 100%;
  height: 100%;
  background-image: url('../images/loader-mask.svg');
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.loader-plate {
  width: 96%;
  height: 80%;
  background-color: #e4ecf1;
}

.loader-progress {
  width: 100%;
  height: 80%;
  background-color: var(--tomato);
  position: absolute;
}

.loader-gif {
  width: 15px;
  height: 15px;
  background-image: url('../images/loader.gif');
  background-position: 0 0;
  background-size: contain;
  margin-right: 4px;
  position: relative;
}

.fifty-nav-logo {
  width: 100%;
  height: 30px;
  cursor: pointer;
  background-image: url('../images/fifty-logo-small.svg');
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 0 auto;
}

.fifty-nav-logo.burger-active {
  opacity: 0;
}

.nav-item-label {
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
}

.nav-item-line {
  width: 100%;
  height: 1px;
  background-color: #f0524b;
  flex: 1;
}

.nav-item-line.burger-active {
  background-color: #fff;
}

.feature-slide-main {
  width: 80%;
  height: 100%;
  background-color: silver;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-end;
  padding-top: 0;
  display: flex;
  position: fixed;
}

.feature-vert-title {
  width: 30px;
  height: 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: absolute;
  top: 30px;
  bottom: auto;
  left: 20px;
  right: auto;
}

.title-vert-rotate {
  width: 30px;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  transform: rotate(-90deg);
}

.overview-panel-bg, .location-panel-bg {
  background-color: #fff;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.video-player {
  height: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.video-placeholder {
  width: 100%;
  height: 100%;
  background-image: url('../images/video-thumb.png');
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.video-play-button {
  width: 60px;
  height: 60px;
  background-image: url('../images/video-play-button.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.building-panel-bg {
  background-color: #fff;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.floor-content {
  max-width: 500px;
  padding-top: 25px;
  margin-right:3vw;
}

.floor-selector-item {
  color: var(--midnight-blue);
  cursor: pointer;
  border-radius: 3px;
  justify-content: space-between;
  padding: 6px 10px;
  font-size: 12px;
  display: flex;
}

.floor-selector-item:hover {
  color: #fff;
  background-color: #fff;
}

.floor-selector-item.floor-selector-item-active {
  background-color: var(--tomato);
  color: #fff;
  align-items: center;
}

.floor-selector-sub {
  font-size: 12px;
}

.floor-area {
  color: var(--midnight-blue);
  padding-left: 1px;
  font-size: 23px;
  font-weight: 600;
}

.floor-selector-list {
  width: 250px;
  max-width: 300px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 0;
}

.floor-selector-title {
  color: var(--midnight-blue);
  font-size: 16px;
  font-weight: 600;
}

.floor-title-wrapper {
  display: flex;
}

.floor-title-area {
  flex: 1;
  padding-left: 17px;
}

.floor-title {
  color: var(--tomato);
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 600;
}

.floor-red-label {
  width: 8px;
  height: 8px;
  background-color: var(--tomato);
  border-radius: 20px;
  margin-top: 6px;
  margin-left: 5px;
  position: absolute;
}

.floor-arrows {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.floor-arrow {
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 3px;
}

.floor-arrow:hover {
  background-color: #0a2342;
}

.floor-arrow.up-arrow {
  background-image: url('../images/up-arrow-icon.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.floor-arrow.up-arrow:hover {
  background-color: #fff;
}

.floor-arrow.down-arrow {
  background-image: url('../images/down-arrow-icon.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.floor-arrow.down-arrow:hover {
  background-color: #fff;
}

.slide-bg {
  opacity: .9;
  background-color: #e4ecf1;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.slide-fullwidth-content {
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
}

.slide-fullwidth-content.contact-content {
  display: flex;
}

.contact-left-col {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.contact-right-col {
  width: 340px;
  height: 100%;
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.contact-header {
  color: var(--midnight-blue);
  letter-spacing: -.6px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 42px;
  line-height: 48px;
}

.red {
  color: var(--tomato);
}

.contact-row {
  width: 100%;
  margin-top: 40px;
  display: flex;
}

.contact-logo {
  width: 82px;
  flex: none;
  margin-right: 50px;
}

.contact-logo.cbre-logo {
  background-image: url('../images/cbre-logo.svg');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 30px;
}

.contact-logo.colliers-logo {
  background-image: url('../images/colliers-logo.svg');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 30px;
}

.contact-name {
  width: auto;
  min-width: 195px;
  color: var(--midnight-blue);
  margin-right: 25px;
  font-size: 16px;
  line-height: 20px;
}

.contact-link {
  color: var(--tomato);
  text-decoration: none;
}

.contact-link:hover {
  color: var(--midnight-blue);
}

.contact-misrep {
  max-width: 80%;
  color: var(--midnight-blue);
  margin-top: 50px;
  font-size: 10px;
  line-height: 16px;
}

.yardnine-logo {
  width: 100px;
  height: 20px;
  background-image: url('../images/yardnine-logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  margin-left: 0;
  position: static;
}

.yardnine-logo.dev-yardnine-logo {
  width: 120px;
  height: 28px;
  position: relative;
}

.contact-dev-line {
  color: var(--midnight-blue);
  margin-bottom: 10px;
  font-size: 12px;
}

.text-link {
  border-bottom: 1px solid var(--tomato);
  color: var(--midnight-blue);
  cursor: pointer;
  font-size: 19px;
}

.text-link:hover {
  color: var(--midnight-blue);
}

.downloads-block {
  width: 100%;
  border-top: 1px solid #babfc2;
  margin-top: 30px;
  padding-top: 10px;
}

.downloads-list {
  margin-top: 20px;
}

.download-item {
  color: var(--tomato);
  cursor: pointer;
  background-image: url('../images/download-icon.svg');
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: auto;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  font-size: 16px;
  line-height: 21px;
}

.download-item:hover {
  color: var(--midnight-blue);
  background-image: url('../images/download-icon-hover.svg');
}

.download-item.spec-download-link {
  font-size: 18px;
}

.download-size {
  font-size: 10px;
}

.overlays {
  z-index: 5000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay.gallery-overlay {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.overlay-content {
  z-index: 5500;
  width: 100%;
  height: 100%;
  padding-left: 130px;
  display: flex;
  position: relative;
}

.overlay-bg.blue-bg {
  background-color: #e4ecf1;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.overlay-title-block {
  width: 300px;
  color: var(--midnight-blue);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  font-size: 32px;
  display: flex;
}

.specification-content {
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 120px 40px;
  display: flex;
  overflow: scroll;
}

.specification-item {
  width: 100%;
  height: 120px;
  max-width: 390px;
  border-top: 1px solid var(--tomato);
  color: var(--midnight-blue);
  background-image: url('../images/spec-showers.svg');
  background-position: 35px;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 0;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
  padding-left: 135px;
  padding-right: 25px;
  font-size: 18px;
  line-height: 22px;
  display: flex;
}

.specification-item.spec-lockers {
  background-image: url('../images/spec-lockers.svg');
  background-position: 34px;
}

.specification-item.spec-elizabeth {
  background-image: url('../images/spec-elizabeth.svg');
  background-position: 20px;
  background-repeat: no-repeat;
  background-size: auto;
}

.specification-item.spec-wired {
  background-image: url('../images/spec-wired.svg');
  background-position: 35px;
  background-repeat: no-repeat;
  background-size: auto;
}

.specification-item.spec-terrace {
  background-image: url('../images/spec-terraces.svg');
  background-position: 47px;
}

.specification-item.spec-breeam {
  background-image: url('../images/spec-breeam.svg');
  background-position: 25px;
}

.specification-item.spec-well {
  background-image: url('../images/spec-well.svg');
  background-position: 30px;
}

.specification-item.spec-density {
  background-image: url('../images/spec-density.svg');
  background-position: 30px;
  padding-right: 49px;
}

.specification-item.spec-cafe {
  background-image: url('../images/spec-cafe.svg');
  background-position: 30px;
}

.specification-item.spec-planting {
  background-image: url('../images/spec-planting.svg');
  background-position: 30px;
}

.specification-item.spec-cycle {
  background-image: url('../images/spec-cycle.svg');
  background-position: 32px;
}

.specification-item.spec-cupola {
  background-image: url('../images/spec-cupola.svg');
  background-position: 30px;
  background-size: auto;
}

.specification-item.spec-meeting {
  background-image: url('../images/spec-meeting.svg');
  background-position: 30px;
  padding-right: 52px;
  font-size: 18px;
}

.specification-item.spec-frame {
  background-image: url('../images/spec-frame.svg');
  background-position: 35px;
}

.specification-list {
  height: 100%;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto)) 497%;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-content: space-between;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  display: flex;
}

.spec-download {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -85px;
  display: flex;
}

.gallery-viewer {
  z-index: 2000;
  width: 85%;
  height: 90%;
  margin-right: 40px;
  position: relative;
}

.gallery-viewer.landing-gallery {
  width: 100%;
  height: 90vh;
  margin-top: 70px;
}

.gallery-viewer.landing-gallery.swiper-main-gallery {
  height: 80vh;
}

.gallery-image {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.gallery-controls {
  height: 55px;
  background-color: var(--tomato);
  display: flex;
  position: absolute;
  top: auto;
  bottom: -28px;
  left: auto;
  right: 80px;
}

.gallery-controls.landing-galllery-controls {
  left: 25px;
  right: auto;
}

.gallery-control-button {
  width: 55px;
  height: 55px;
  cursor: pointer;
}

.gallery-control-button.gallery-prev {
  background-image: url('../images/arrow-prev.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.gallery-control-button.gallery-prev:hover {
  background-color: #fff;
}

.gallery-control-button.gallery-next {
  background-image: url('../images/arrow-next.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.gallery-control-button.gallery-next:hover {
  background-color: #fff;
}

.gallery-thumb-list {
  align-items: center;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.gallery-thumb {
  width: 10px;
  height: 10px;
  border: 2px solid var(--midnight-blue);
  border-radius: 20px;
  margin-left: 6px;
  margin-right: 6px;
}

.gallery-thumb.gallery-thumb-active {
  background-color: var(--midnight-blue);
}

.overlay-main-container {
  width: 100%;
  overflow: scroll;
}

.developer-image {
  height: 275px;
  margin-bottom: 10px;
}

.developer-image.exchange-image {
  background-image: url('../images/dev-exchange-image.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.developer-image.fitzroy-image {
  background-image: url('../images/dev-fitzroy-image.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.developer-image.burlington-image {
  background-image: url('../images/dev-burlington-image.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.developer-image.bedford-image {
  background-image: url('../images/dev-bedford-image.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.invesco-logo {
  width: 154px;
  height: 27px;
  background-image: url('../images/invesco-logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.developer-images-wrapper {
  margin-bottom: 45px;
  display: flex;
}

.developer-text {
  max-width: 590px;
  color: var(--midnight-blue);
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 24px;
}

.development-col-right {
  width: 320px;
  background-color: #0a1e38;
  flex: none;
  padding: 175px 45px 45px;
}

.gt-logo {
  width: 165px;
  height: 32px;
  background-image: url('../images/gt-logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.development-content {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.development-label {
  color: #ffcf40;
  margin-bottom: 15px;
}

.sheppard-logo {
  width: 227px;
  height: 15px;
  background-image: url('../images/sheppard-logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.developer-image-cell {
  width: 50%;
  margin-right: 20px;
}

.development-col-left {
  width: 100%;
  background-color: #fff;
  padding: 45px 30px 45px 45px;
}

.aecom-logo {
  width: 110px;
  height: 26px;
  background-image: url('../images/aecom-logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.developer-image-label {
  color: var(--tomato);
}

.development-cell {
  margin-bottom: 60px;
}

.development-cell.developer {
  margin-top: 77px;
  margin-bottom: 40px;
}

.watkins-logo {
  width: 166px;
  height: 68px;
  background-image: url('../images/watkins-logo.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.avison-logo {
  width: 110px;
  height: 58px;
  background-image: url('../images/avison-logo.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.virtual-viewer {
  z-index: 400;
  width: 100%;
  height: 90%;
  background-color: #fff;
  position: relative;
}

.plan-viewer {
  z-index: 400;
  width: 100%;
  height: 90%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding-left: 80px;
  display: flex;
  position: relative;
}

.plan-image {
  width: 60%;
  height: 70%;
  cursor: grab;
  background-image: url('../images/plan-placeholder.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10%;
}

.floorplan-download-link {
  color: #f0524b;
  cursor: pointer;
  background-image: url('../images/download-icon.svg');
  background-position: 0%;
  background-repeat: no-repeat;
  margin-top: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  font-size: 14px;
}

.floorplan-download-link:hover {
  color: #0a2342;
  background-image: url('../images/download-icon-hover.svg');
}

.specs-item {
  font-size: 16px;
}

.specs-item.specs-item-total {
  margin-top: 20px;
}

.specs-list {
  color: #0a2342;
  margin-top: 40px;
}

.floorplan-title-block {
  z-index: 100;
  position: static;
  top: 100px;
  left: 30px;
}

.floorplan-switch-wrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.floorplan-switch-label {
  color: #f0524b;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
  font-size: 16px;
}

.floorplan-switch-label:hover, .floorplan-switch-label.active {
  color: #0a2342;
}

.floorplan-control-block {
  position: absolute;
  top: auto;
  bottom: 30px;
  left: auto;
  right: 30px;
}

.floorplan-control-button {
  width: 45px;
  height: 45px;
  cursor: pointer;
  margin-top: 5px;
}

.floorplan-control-button.zoom-in {
  background-image: url('../images/zoom-in-icon.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.floorplan-control-button.zoom-in:hover {
  background-image: url('../images/zoom-in-icon-hover.svg');
}

.floorplan-control-button.zoom-out {
  background-image: url('../images/zoom-out-icon.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.floorplan-control-button.zoom-out:hover {
  background-image: url('../images/zoom-out-icon-hover.svg');
}

.floorplan-control-button.zoom-reset {
  background-image: url('../images/zoom-reset-icon.svg');
  background-position: 50%;
  background-repeat: no-repeat;
}

.floorplan-control-button.zoom-reset:hover {
  background-image: url('../images/zoom-reset-icon-hover.svg');
}

.mclaren-logo {
  width: 130px;
  height: 22px;
  background-image: url('../images/mclaren-logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.burger-button {
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-image: url('../images/burger-button.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  flex: none;
}

.burger-button:hover {
  background-color: #fff;
}

.burger-button.burger-close-button {
  background-image: url('../images/burger-close-icon.svg');
}

.burger-button.burger-close-button:hover {
  background-color: #e2413a;
}

.feature-slide-image {
  background-color: var(--midnight-blue);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.contact-logo-group {
  margin-bottom: 35px;
  line-height: 14px;
}

.invesco-contact-logo {
  width: 140px;
  height: 30px;
  background-image: url('../images/invesco-logo.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 15px;
  margin-left: 0;
  position: static;
}

.invesco-contact-logo.dev-yardnine-logo {
  width: 120px;
  height: 28px;
  position: relative;
}

.feature-item-title {
  border-bottom: 1px #d8e6f3;
  border-left: 2px solid var(--tomato);
  color: var(--midnight-blue);
  background-color: #f3fbff;
  border-radius: 0 4px 4px 0;
  margin-bottom: 5px;
  padding: 10px 39px 14px 15px;
  font-size: 15px;
}

.feature-item {
  padding: 0;
}

.feature-title {
  color: var(--tomato);
}

.feature-copy {
  color: var(--midnight-blue);
  margin-top: 5px;
  font-size: 12px;
  line-height: 17px;
}

.feature-slide-wrapper {
  z-index: 1000;
  height: auto;
  min-height: 100%;
  align-items: flex-end;
  padding-top: 20vh;
  display: flex;
  position: absolute;
}

.burger-wrapper {
  z-index: -1;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.burger-bg {
  z-index: 1500;
  background-color: var(--tomato);
  opacity: .95;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.burger-content {
  z-index: 2000;
  height: 100%;
  justify-content: flex-end;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: relative;
}

.burger-item-label {
  align-items: center;
  margin-top: 0;
  margin-bottom: 5px;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 28px;
  line-height: 30px;
}

.burger-item-label:hover {
  color: var(--midnight-blue);
}

.burger-item-label.burger-mobile-item {
  display: none;
}

.burger-item {
  width: 100%;
  height: auto;
  color: #fff;
  letter-spacing: .3px;
  cursor: pointer;
  flex-flow: column wrap;
  align-items: flex-start;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.burger-item:hover {
  border-bottom-color: #0a2342;
}

.burger-item.nav-active {
  border-bottom-color: var(--midnight-blue);
  color: var(--midnight-blue);
}

.burger-item.nav-active:hover {
  border-bottom-color: var(--midnight-blue);
}

.burger-item.nav-active.burger-active, .burger-item.burger-active {
  color: #fff;
}

.burger-divider {
  width: 50px;
  height: 1px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.burger-left-col {
  width: 100%;
  position: relative;
}

.fifty-address {
  color: #fff;
  background-image: url('../images/address-pin.svg');
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: auto;
  padding-left: 20px;
  position: absolute;
  top: -40px;
  left: 0;
}

.burger-fifty-lines {
  font-size: 28px;
  line-height: 35px;
}

.intro-slide-bg {
  width: 88vw;
  opacity: .9;
  background-image: url('../images/ext4.jpg');
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.intro-slidebg-gradient {
  width: 50%;
  background-image: linear-gradient(to right, var(--midnight-blue), rgba(10, 35, 66, 0));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.intro-slide-content {
  width: 550px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.intro-header-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 40px;
  display: flex;
}

.intro-button {
  background-color: var(--tomato);
  color: var(--midnight-blue);
  cursor: pointer;
  padding: 40px 50px 40px 40px;
  font-size: 26px;
}

.intro-button:hover {
  color: var(--tomato);
  background-color: #fff;
}

.intro-header-list {
  width: 100%;
}

.intro-header-item {
  overflow: hidden;
}

.intro-header-text {
  max-width: 720px;
  color: #fff;
  margin-top: -3px;
  padding-bottom: 3px;
  font-size: 44px;
  line-height: 47px;
}

.intro-header-text.available-text {
  color: var(--gold);
}

.slide-placer {
  display: none;
}

.amenity-toggle {
  width: 140px;
  flex: none;
  margin-left: 42px;
}

.amenity-label {
  color: var(--tomato);
  font-size: 10px;
}

.location-title-block {
  border-bottom: 1px solid var(--tomato);
  padding-bottom: 10px;
  display: flex;
}

.video-intro-slide-bg {
  width: 100%;
  height: 100vh;
  opacity: .9;
  background-image: url('../images/intro-preroll-placeholder.jpg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
  display:flex;
  align-items:center;
  justify-content:center;
}

.occupiers-list {
  flex-wrap: wrap;
  margin-top: 50px;
  display: flex;
}

.occupier-item {
  width: 25%;
  height: 18vh;
  border: 1px none var(--alice-blue);
  margin-bottom: 0;
  margin-right: 0;
}

.occupier-item.occ-adameve {
  background-image: url('../images/logo-adam-eve.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 160px;
}

.occupier-item.logo-capital {
  background-image: url('../images/logo-capital.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-future {
  background-image: url('../images/logo-future.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-hellman {
  background-image: url('../images/logo-hellman.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-iqvia {
  background-image: url('../images/logo-iqvia.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-kingfisher {
  background-image: url('../images/logo-kingfisher.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 150px;
}

.occupier-item.logo-ms {
  background-image: url('../images/logo-ms.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-microsoft {
  background-image: url('../images/logo-microsoft.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-mitsu {
  background-image: url('../images/logo-mitsubishi.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-payment {
  background-image: url('../images/logo-payment.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-premier {
  background-image: url('../images/logo-premier.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-sony {
  background-image: url('../images/logo-sony.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-vertex {
  background-image: url('../images/logo-vertex.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-visa {
  background-image: url('../images/logo-visa.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-vodafone {
  background-image: url('../images/logo-vodafone.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-mars {
  background-image: url('../images/logo-mars.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occupier-item.logo-takeda {
  background-image: url('../images/logo-takeda.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.overview-notice {
  z-index: 100;
  background-color: var(--tomato);
  color: var(--alice-blue);
  padding: 5px 15px 5px 10px;
  font-size: 17px;
  position: absolute;
  top: auto;
  bottom: 70px;
  left: 70px;
  right: auto;
}

.landing-page-intro-section {
  width: 100%;
  height: 100vh;
  background-color: var(--midnight-blue);
  position: relative;
}

.landing-page-intro-text {
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 70px;
  display: flex;
  position: relative;
}

.landing-page-play-button {
  color: #fff;
  cursor: pointer;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.play-button-label {
  margin-left: 17px;
  font-size: 20px;
}

.lp-arrow {
  width: 100%;
  height: 37px;
  background-image: url('../images/scroll-icon.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: auto;
  bottom: 70px;
  left: 0%;
  right: 0%;
}

.landing-page-content-section {
  background-color: #f4faff;
  padding: 70px 0 80px;
}

.landing-page-h2 {
  max-width: 750px;
  color: #0a2342;
  margin-top: 0;
  font-size: 22px;
  font-weight: 500;
  line-height: 31px;
}

.lp-margin-content-block {
  padding-left: 70px;
}

.swiper-ext-gal {
  margin-top: 70px;
  margin-bottom: 70px;
}

.swiper-wrapper-ext-gal {
  width: 100%;
  height: auto;
  display: flex;
}

.swiper-slide-ext-gal {
  width: 550px;
  height: 550px;
  background-color: #6d789e;
}

.swiper-thumb-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.swiper-occ-gal {
  width: 100%;
}

.swiper-wrapper-occ-gal {
  display: flex;
}

.swiper-slide-occ-gal {
  width: 420px;
  height: 270px;
  background-color: var(--midnight-blue);
}

.landing-page-window {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.lp-avail-block {
  background-color: #fff;
  margin-bottom: 10px;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 25px;
}

.lp-avail-list {
  margin-top: 30px;
  padding-right: 40px;
}

.lp-avail-item {
  color: var(--midnight-blue);
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.lp-avail-item:hover {
  color: var(--tomato);
}

.lp-avail-item.unavailable {
  opacity: .4;
  pointer-events: none;
}

.lp-avail-text {
  font-size: 16px;
}

.lp-avail-text.right-align {
  text-align: right;
}

.lp-avail-text.right-align.red {
  color: var(--tomato);
}

.unavailable {
  opacity: .4;
  color: var(--midnight-blue);
}

.avail-notice {
  color: var(--midnight-blue);
  background-color: #e4ecf1;
  background-image: url('../images/eye-icon.svg');
  background-position: 3% 48%;
  background-repeat: no-repeat;
  background-size: auto;
  margin-top: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
  font-size: 12px;
}

.sub {
  font-size: 10px;
}

.lp-window-button {
  background-color: var(--gold);
  cursor: pointer;
  background-image: url('../images/lp-button-arrow.svg');
  background-position: 7%;
  background-repeat: no-repeat;
  background-size: auto;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 23px;
  padding-left: 75px;
  font-size: 24px;
  line-height: 26px;
}

.lp-window-button:hover {
  background-color: var(--tomato);
}

.lp-window-panel {
  width: 400px;
}

.swiper-main-wrapper, .swiper-main-slide {
  width: 100%;
  height: 100%;
}

.occ-gal-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.occ-nav-button {
  width: 50px;
  height: 200px;
  cursor: pointer;
  margin-bottom: 60px;
  margin-left: 20px;
  margin-right: 20px;
}

.occ-nav-button.occ-prev-button {
  background-image: url('../images/occ-gal-prev.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  margin-bottom: 40px;
}

.occ-nav-button.occ-prev-button:hover {
  background-image: url('../images/occ-gal-prev-hover.svg');
}

.occ-nav-button.occ-next-button {
  background-image: url('../images/occ-gal-next.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
}

.occ-nav-button.occ-next-button:hover {
  background-image: url('../images/occ-gal-next-hover.svg');
}

.lp-window-spacer {
  display: none;
}

@media screen and (max-width: 991px) {
  .body {
    height: 100vh;
  }

  .app, .interface {
    position: static;
  }

  .render {
    z-index: -1;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .slide {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    justify-content: flex-start;
    align-items: flex-end;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 0;
    display: block;
    position: relative;
  }

  .slide.feature-slide {
    flex-direction: column;
    padding-left: 85px;
  }

  .slide.floor-select {
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 50px;
    padding-right: 20px;
    display: flex;
  }

  .slide.full-width-slide {
    justify-content: flex-start;
    padding-top: 90px;
  }

  .slide.full-width-slide.landing-page-slide {
    padding-top: 0;
    padding-left: 0;
  }

  .slide.intro-slide {
    height: 100vh;
    min-height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0;
    padding-left: 0;
  }

  .slide.slide-bottom-aligned {
    justify-content: flex-end;
    display: flex;
  }

  .viz-info {
    height: 65vh;
    justify-content: flex-end;
    align-items: flex-end;
    left: auto;
    right: 0;
  }

  .nav-list {
    display: none;
  }

  .nav-list.burger-list {
    width: 60%;
    margin-right: 0;
    display: block;
  }

  .slide-panel.panel-red {
    width: 100%;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    position: relative;
  }

  .copy, .slide-horiz-nav {
    max-width: 700px;
  }

  .slide-popout {
    width: 100%;
    order: 1;
    margin-top: 0;
    margin-left: 0%;
  }

  .slide-popout.elizabeth-popout {
    width: 260px;
    height: 170px;
    bottom: 133px;
  }

  .panel-padding-container {
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .enlarge-button.feature-button {
    display: none;
  }

  .instructions-wrapper {
    align-items: flex-end;
  }

  .instruction-item {
    align-self: flex-end;
  }

  .instruction {
    padding: 7px 7px 7px 30px;
    font-size: 9px;
  }

  .instruction.orbit-icon {
    letter-spacing: .4px;
    align-self: flex-end;
    padding-left: 30px;
  }

  .instruction-reset-button {
    align-self: flex-end;
  }

  .feature-slide-container {
    width: 100%;
  }

  .slide-close-container {
    left: 15px;
  }

  .feature-slide-main {
    width: 100%;
    height: 380px;
    min-height: 380px;
    order: -1;
    padding-top: 0;
    position: relative;
  }

  .video-placeholder {
    background-position: 0%;
  }

  .floor-content {
    width: 210px;
    padding-top: 0;
  }

  .floor-selector-item {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .floor-area {
    font-size: 24px;
  }

  .floor-selector-list {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .floor-selector-title {
    font-size: 14px;
  }

  .floor-title-area {
    padding-left: 0;
  }

  .floor-title {
    font-size: 24px;
    line-height: 26px;
  }

  .slide-fullwidth-content.contact-content {
    flex-direction: column;
    padding-left: 0;
  }

  .contact-left-col {
    flex-flow: wrap;
    justify-content: flex-start;
    margin-bottom: 60px;
  }

  .contact-header {
    width: 100%;
  }

  .contact-row {
    width: 45%;
    flex-direction: column;
  }

  .contact-logo.cbre-logo, .contact-logo.colliers-logo {
    height: 60px;
    margin-bottom: 15px;
  }

  .contact-name {
    width: 200px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
  }

  .overlay.gallery-overlay {
    padding-left: 90px;
  }

  .overlay-content {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    padding-left: 95px;
    overflow: scroll;
  }

  .overlay-title {
    font-size: 24px;
  }

  .specification-content {
    padding: 60px 20px 80px 0;
    overflow: visible;
  }

  .specification-item {
    width: 100%;
    height: 120px;
  }

  .gallery-viewer {
    width: 100%;
    height: 70vw;
    margin-right: 0;
  }

  .gallery-controls {
    right: 40px;
  }

  .developer-image {
    height: 180px;
  }

  .developer-text {
    font-size: 14px;
    line-height: 20px;
  }

  .development-col-right {
    width: 250px;
    padding-left: 25px;
  }

  .sheppard-logo {
    width: 200px;
  }

  .development-col-left {
    padding-left: 30px;
  }

  .developer-image-label {
    font-size: 12px;
    line-height: 18px;
  }

  .plan-viewer {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .plan-image {
    width: 100%;
    height: 50%;
    margin-top: 0;
    margin-left: 0%;
  }

  .specs-item {
    font-size: 12px;
    line-height: 16px;
  }

  .specs-list {
    margin-top: 20px;
  }

  .floorplan-title-block {
    top: 35px;
  }

  .floorplan-switch-wrapper {
    position: relative;
    bottom: 0;
  }

  .burger-button {
    padding-bottom: 0;
    padding-right: 0;
  }

  .feature-slide-image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .feature-slide-wrapper {
    padding-top: 0;
    position: relative;
  }

  .burger-wrapper {
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

  .burger-bg {
    height: auto;
    position: fixed;
  }

  .burger-content {
    width: 100%;
    height: auto;
    padding-bottom: 60px;
  }

  .burger-item-label.burger-mobile-item {
    display: block;
  }

  .burger-item {
    margin-bottom: 40px;
  }

  .burger-fifty-lines {
    font-size: 24px;
    line-height: 29px;
  }

  .intro-slide-bg {
    width: 100%;
    height: 100%;
    background-position: 50%;
    position: relative;
    left: 0;
  }

  .intro-slidebg-gradient {
    width: 100%;
    height: 140px;
    background-image: linear-gradient(to top, var(--midnight-blue), rgba(10, 35, 66, 0));
    top: auto;
    right: 0;
  }

  .intro-slide-content {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    top: auto;
  }

  .intro-header-wrapper {
    width: 100%;
    margin-top: -50px;
    padding-bottom: 50px;
  }

  .intro-button {
    flex: none;
  }

  .intro-header-text {
    font-size: 40px;
    line-height: 44px;
  }

  .slide-placer {
    width: 100%;
    height: 65vh;
    display: block;
  }

  .video-intro-slide-bg {
    width: 100%;
    height: 100%;
    background-position: 50%;
    position: absolute;
    left: 0;
  }

  .occupier-item {
    width: 33%;
  }

  .overview-notice {
    width: 231px;
    padding-left: 10px;
    padding-right: 10px;
    bottom: 60px;
    left: 30px;
  }

  .landing-page-intro-text {
    width: 80%;
    padding-left: 30px;
  }

  .landing-page-h2 {
    max-width: 610px;
  }

  .lp-margin-content-block {
    padding-left: 30px;
  }

  .swiper-slide-ext-gal {
    height: 440px;
  }

  .swiper-slide-occ-gal {
    height: 200px;
  }

  .landing-page-window {
    height: auto;
    justify-content: flex-end;
  }

  .avail-notice {
    background-position: 1% 48%;
  }

  .lp-window-button {
    background-position: 3%;
    padding-top: 10px;
    padding-bottom: 13px;
    padding-left: 55px;
    font-size: 18px;
  }

  .lp-window-panel {
    width: 100%;
  }

  .occ-nav-button.occ-prev-button, .occ-nav-button.occ-next-button {
    display: none;
  }

  .lp-window-spacer {
    width: 100%;
    height: 55vh;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .nav {
    padding-right: 10px;
  }

  .slide {
    min-width: auto;
  }

  .slide.feature-slide {
    overflow: visible;
  }

  .slide.intro-slide {
    min-width: auto;
  }

  .viz-info {
    height: 65vh;
  }

  .copy {
    font-size: 15px;
    line-height: 23px;
  }

  .slide-popout.elizabeth-popout {
    right: 15px;
  }

  .panel-padding-container {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .compass {
    width: 20px;
    height: 20px;
  }

  .instructions-wrapper {
    margin-top: 10px;
    display: none;
  }

  .instruction-item {
    margin-bottom: 3px;
  }

  .instruction.orbit-icon {
    background-position: 5% 31%;
    background-size: 11px;
    padding: 4px 8px 4px 22px;
    font-size: 8px;
  }

  .instruction.pan-icon {
    background-position: 6px 24%;
    background-size: 11px;
    padding: 4px 8px 4px 22px;
    font-size: 8px;
  }

  .instruction.zoom-icon {
    background-position: 7px 41%;
    background-size: 9px;
    padding: 4px 8px 4px 22px;
    font-size: 8px;
  }

  .instruction-reset-button {
    background-position: 8px 6px;
    margin-top: 0;
    padding: 6px 8px 6px 30px;
    font-size: 8px;
  }

  .feature-slide-container {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .h2.feature-vert {
    text-align: left;
    font-size: 20px;
    line-height: 24px;
  }

  .feature-slide-main {
    height: 300px;
    min-height: auto;
    overflow: visible;
  }

  .feature-vert-title {
    width: 100%;
    height: auto;
    align-items: flex-start;
    top: 15px;
    left: 20px;
  }

  .title-vert-rotate {
    width: auto;
    transform: rotate(0);
  }

  .floor-content {
    width: 167px;
  }

  .floor-selector-item {
    padding-left: 6px;
    padding-right: 6px;
    font-size: 10px;
  }

  .floor-area {
    font-size: 20px;
  }

  .floor-selector-title {
    font-size: 12px;
  }

  .floor-title {
    margin-bottom: 2px;
    font-size: 20px;
    line-height: 20px;
  }

  .slide-fullwidth-content.contact-content {
    padding-right: 20px;
  }

  .contact-header {
    font-size: 28px;
    line-height: 31px;
  }

  .contact-row {
    width: 100%;
    flex-direction: column;
    margin-right: 21px;
  }

  .contact-logo.cbre-logo {
    height: 21px;
    margin-bottom: 20px;
  }

  .contact-logo.colliers-logo {
    height: 54px;
    margin-bottom: 20px;
  }

  .contact-name {
    margin-bottom: 15px;
  }

  .contact-misrep {
    max-width: 100%;
  }

  .overlay.gallery-overlay {
    overflow: scroll;
  }

  .overlay-content {
    padding-top: 0;
  }

  .overlay-bg.blue-bg {
    position: fixed;
  }

  .gallery-viewer {
    height: 90vw;
  }

  .gallery-viewer.landing-gallery.swiper-main-gallery {
    height: 44vh;
  }

  .development-col-right {
    width: 100%;
    padding-top: 50px;
  }

  .development-content {
    flex-direction: column;
  }

  .development-col-left {
    padding-left: 20px;
    padding-right: 20px;
  }

  .plan-viewer {
    height: auto;
    min-height: 100vh;
    justify-content: flex-start;
    padding-top: 20px;
    padding-right: 20px;
  }

  .plan-image {
    height: 330px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .floorplan-download-link {
    margin-top: 15px;
  }

  .floorplan-title-block {
    left: 15px;
  }

  .floorplan-switch-wrapper {
    bottom: 10px;
  }

  .floorplan-control-block {
    bottom: 15px;
    right: 15px;
  }

  .burger-content {
    flex-direction: column;
  }

  .burger-fifty-lines {
    display: none;
  }

  .intro-slide-bg {
    height: 55vh;
    flex: none;
  }

  .intro-slide-content {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .intro-header-wrapper {
    align-items: flex-end;
    margin-top: 0;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .intro-button {
    width: 100%;
    padding: 20px 30px 20px 20px;
    font-size: 22px;
  }

  .intro-header-text {
    font-size: 30px;
    line-height: 32px;
  }

  .amenity-toggle {
    margin-left: 30px;
  }

  .video-intro-slide-bg {
    flex: none;
  }

  .occupier-item {
    width: 50%;
  }

  .overview-notice {
    left: 20px;
  }

  .landing-page-intro-text {
    padding-left: 20px;
  }

  .lp-arrow {
    background-position: 94%;
  }

  .landing-page-h2 {
    max-width: 390px;
    padding-right: 30px;
  }

  .lp-margin-content-block {
    padding-left: 20px;
  }

  .lp-window-spacer {
    height: 50vh;
  }
}

@media screen and (max-width: 479px) {
  .nav {
    padding-left: 15px;
    padding-right: 0;
  }

  .slide {
    min-width: auto;
    padding-top: 0;
    padding-left: 10px;
  }

  .slide.feature-slide {
    min-width: auto;
    padding-top: 80px;
    padding-left: 0;
  }

  .slide.floor-select {
    padding-top: 80vh;
    padding-right: 10px;
  }

  .slide.full-width-slide {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .viz-info {
    height: 55vh;
    padding-bottom: 15px;
    padding-right: 15px;
  }

  .copy {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }

  .slide-horiz-nav {
    flex-direction: column;
  }

  .slide-horiz-nav.amenity-toggle-nav {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .slide-horiz-item {
    margin-bottom: 9px;
    margin-right: 10px;
    font-size: 17px;
  }

  .slide-popout {
    height: 35vh;
    min-height: 300px;
  }

  .panel-padding-container {
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .feature-slide-container {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .feature-img-block {
    height: 250px;
  }

  .h2.feature-vert {
    font-size: 20px;
    line-height: 21px;
  }

  .slide-close-container {
    width: 100%;
    height: 54px;
    justify-content: center;
    top: 10px;
    left: 0;
  }

  .feature-slide-main {
    width: 100%;
    align-self: flex-start;
  }

  .feature-vert-title {
    width: auto;
    top: 25px;
    left: 20px;
  }

  .floor-content {
    width: 100%;
    max-width: none;
    margin-right:0;
  }

  .floor-area {
    font-size: 16px;
  }

  .floor-title-area {
    padding-left: 5px;
  }

  .floor-title {
    font-size: 23px;
    line-height: 26px;
  }

  .contact-right-col {
    width: 100%;
  }

  .contact-header {
    font-size: 22px;
    line-height: 25px;
  }

  .download-item {
    font-size: 12px;
  }

  .overlay.gallery-overlay {
    align-items: flex-start;
    padding-top: 80px;
    padding-left: 0;
  }

  .overlay-content {
    padding-top: 100px;
    padding-left: 15px;
  }

  .overlay-title-block {
    width: 100%;
    padding-bottom: 20px;
  }

  .specification-content {
    padding-right: 10px;
  }

  .specification-item {
    height: auto;
    text-align: center;
    background-position: 50% 30px;
    justify-content: center;
    padding: 140px 15px 20px;
    font-size: 14px;
  }

  .specification-item.spec-lockers {
    background-position: 50% 30px;
    padding-top: 130px;
  }

  .specification-item.spec-elizabeth {
    height: auto;
    text-align: center;
    background-position: 50% 30px;
    background-size: 40%;
    padding: 140px 20px 20px;
  }

  .specification-item.spec-wired, .specification-item.spec-terrace {
    background-position: 50% 30px;
  }

  .specification-item.spec-breeam {
    background-position: 50% 45px;
    padding-top: 115px;
  }

  .specification-item.spec-well {
    background-position: 50% 30px;
  }

  .specification-item.spec-density {
    background-position: 50% 30px;
    padding-top: 130px;
  }

  .specification-item.spec-cafe {
    background-position: 50% 30px;
    padding-top: 140px;
  }

  .specification-item.spec-planting {
    background-position: 50% 30px;
  }

  .specification-item.spec-cycle {
    background-position: 50% 30px;
    padding-top: 110px;
  }

  .specification-item.spec-cupola {
    background-position: 50% 30px;
  }

  .specification-item.spec-meeting {
    background-position: 50% 33px;
    padding-top: 130px;
  }

  .specification-item.spec-frame {
    background-position: 50% 30px;
  }

  .spec-download {
    margin-bottom: 0;
  }

  .gallery-viewer {
    height: 80vh;
  }

  .gallery-image {
    height: 100%;
  }

  .gallery-controls {
    bottom: 0;
    right: 0;
  }

  .gallery-thumb-list {
    display: none;
  }

  .developer-images-wrapper {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .developer-image-cell {
    width: 100%;
    margin-bottom: 30px;
  }

  .plan-image {
    width: 90%;
    height: 330px;
  }

  .floorplan-download-link {
    margin-top: 10px;
  }

  .floorplan-switch-label, .floorplan-switch-label.active {
    font-size: 12px;
  }

  .floorplan-control-block {
    bottom: auto;
    right: 10px;
  }

  .floorplan-control-button.zoom-in {
    width: 30px;
    height: 30px;
  }

  .floorplan-control-button.zoom-out, .floorplan-control-button.zoom-reset {
    width: 30px;
    height: 30px;
    margin-top: 0;
  }

  .feature-slide-wrapper {
    width: 100%;
  }

  .intro-header-text {
    font-size: 23px;
    line-height: 28px;
  }

  .slide-placer {
    height: 55vh;
  }

  .amenity-toggle {
    width: 100%;
    margin-top: 25px;
    margin-left: 0;
  }

  .location-title-block {
    flex-direction: column;
  }

  .occupier-item {
    width: 100%;
  }

  .overview-notice {
    width: 200px;
    margin-top: 20px;
    padding-left: 15px;
    font-size: 14px;
    line-height: 16px;
    bottom: 40px;
  }

  .landing-page-intro-text {
    width: 93%;
    padding-left: 15px;
  }

  .lp-arrow {
    bottom: 40px;
  }

  .landing-page-content-section {
    padding-bottom: 50px;
  }

  .landing-page-h2 {
    font-size: 16px;
    line-height: 22px;
  }

  .swiper-ext-gal {
    margin-bottom: 30px;
  }

  .occ-gal-wrapper {
    margin-top: 45px;
  }

  .occ-nav-button.occ-prev-button, .occ-nav-button.occ-next-button {
    display: none;
  }

  .lp-window-spacer {
    height: 50vh;
  }
}


@font-face {
  font-family: 'Lineto circular';
  src: url('../fonts/lineto-circular-bold.woff2') format('woff2'), url('../fonts/lineto-circular-bold.eot') format('embedded-opentype'), url('../fonts/lineto-circular-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lineto circular';
  src: url('../fonts/lineto-circular-medium.woff2') format('woff2'), url('../fonts/lineto-circular-medium.eot') format('embedded-opentype'), url('../fonts/lineto-circular-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.render {
  width: 100%;
  height: 100%;
}

.app {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
}

.ui {
  display: fixed;
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  z-index: 500;
}

.no-event {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
}

.hidden {
  display: none !important;
}

.no-scroll {
  overflow: hidden !important;
}

.debug {
  position: relative;
  z-index: 1100;
  height: 100%;
  overflow-y: scroll;
}

.fade,
.auto-fade,
.fade-out {
  opacity: 0 !important;
}

.anim-all-200 {
  transition: all ease-in-out 200ms;
}

.anim-all-400 {
  transition: all ease-in-out 400ms;
}

.anim-slide-up {
  transform: translateY(20px);
}

.debug-stats-panel {
  position: fixed;
  left: 45px;
  top: 45px;
}

.interface {
  position: fixed;
  /* overflow: hidden; */
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.tracker-item {
  margin-bottom: 5px;
  padding: 8px 5px 5px 10px;
  border-left-style: solid;
  border-left-width: 3px;
  background-color: #e9e9e9;
}

.tracker-item.variable-item {
  border-left-color: #9510e0;
}

.tracker-item.entity-item {
  border-left-color: #00ff9a;
  cursor: pointer;
}

.tracker-item.entity-item:hover {
  background-color: silver;
}

.tracker-item.entity-item.active {
  background-color: #1f191b;
  color: #fff;
}

.state-label {
  height: 45px;
  min-width: 200px;
  padding-top: 12px;
  padding-left: 16px;
  border-left: 2px solid #00ff9a;
  border-radius: 3px;
  background-color: #1f191b;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.state-label {
  cursor: pointer;
}

.state-selector-list {
  display: none;
}

.state-label-wrapper:hover .state-selector-list {
  display: block;
}

.tracker-wrap.config-items-wrap {
  padding-top: 15px;
}

.tracker-wrap.variables-wrap {
  margin-top: 15px;
}

.tracker-title {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
}

.tracker-title.selected-var-title {
  margin-bottom: 10px;
}

.entity-value {
  font-size: 10px;
  font-weight: 600;
}

.state-label-wrapper {
  position: relative;
}

.state-nav-wrapper {
  position: fixed;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 25px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.tracker-list {
  margin-top: 5px;
}

.state-list-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 16px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.state-list-item:hover {
  color: #00ff9a;
}

.debug-wrap {
  position: absolute;
  left: 20px;
  top: 90px;
  z-index: 1000;
  width: 200px;
  padding: 10px;
  border-top: 3px solid #000;
  background-color: #fff;
}

.debug-wrap.debug-wrap-config {
  position: fixed;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 100px;
}

.debug-wrap.debug-floor-config {
  position: fixed;
  left: auto;
  top: auto;
  right: 20px;
  bottom: 300px;
  display: none;
}

.debug-wrap.selected-vars-wrap {
  position: fixed;
  left: 235px;
  top: 90px;
  right: auto;
  padding-bottom: 0px;
  border-radius: 3px;
  background-color: #1f191b;
  color: #fff;
}

.state-button {
  width: 45px;
  height: 45px;
  margin-left: 5px;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
}

.state-button:hover {
  background-color: #616161;
}

.state-button.next-button {
  background-image: url("../images/next-arrow.svg");
  background-position: 55% 50%;
  background-repeat: no-repeat;
}

.state-button.prev-button {
  background-image: url("../images/prev-arrow.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.state-selector-list {
  padding: 10px 0px;
  border-radius: 3px;
  background-color: #1f191b;
}

.reset-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 3px;
  background-color: #fff;
  background-image: url("../images/reset-icon.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #616161;
}

.show-floor-button {
  height: 45px;
  margin-right: 15px;
  padding-top: 12px;
  padding-right: 12px;
  padding-left: 40px;
  border-radius: 3px;
  background-color: #fff;
  background-image: url("../images/radial2.svg");
  background-position: 13px 50%;
  background-repeat: no-repeat;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.show-floor-button:hover {
  background-color: #1f191b;
  color: #fff;
}

.show-floor-button.active {
  background-image: url("../images/radial2-checked.svg");
}

.config-list-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  background-image: url("../images/radial.svg");
  background-position: 0px 50%;
  background-repeat: no-repeat;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}

.config-list-item:hover {
  color: #f0524a;
}

.config-list-item.active {
  background-image: url("../images/radial-checked.svg");
}

.entity-title {
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}

.debug.hidden {
  display: none;
}

.slide {
  overflow-x: hidden !important;
}

.content-fade {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 200px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#c6d3ca),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(90deg, #e4ecf1, hsla(0, 0%, 100%, 0));
}

.content-right-fade {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 600px;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#c6d3ca),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(270deg, #e4ecf1, hsla(0, 0%, 100%, 0));
}

.content-top-fade {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: 140px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#c6d3ca),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #e4ecf1, hsla(0, 0%, 100%, 0));
}

.content-bottom-fade {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: 140px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#c6d3ca),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(0deg, #e4ecf1, hsla(0, 0%, 100%, 0));
}

.nav-active .nav-item-line,
.nav-item:hover .nav-item-line {
  background-color: #0a2342;
}

.feature-slide {
  overflow-x: hidden !important;
  z-index: 1000;
}

.feature-slide-main {
  background-repeat: no-repeat;
  background-position: 0% 50%;
  overflow: hidden;
  /* position: relative; */
  /* background-size: cover; */
}

.loader-progress {
  clip-path: inset(0% 100% 0% 0%);
  transition: all 400ms ease-in-out;
}

.loader-group {
  transition: all 200ms ease-in-out;
}

.loader-bg {
  clip-path: inset(0% 0% 0% 0%);
  transition: all 800ms ease-in-out;
}

.loader-outro {
  clip-path: inset(0% 0% 0% 100%);
}

.loader-content {
  transition: all 400ms ease-in-out;
}

.parallax {
  position: relative;
  left: 0px;
  top: 0px;
}

.feature-img-block {
  background-size: contain;
}

.feature-smart-img {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: calc(100% - 520px);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.small-copy {
  font-size: 13px;
  font-weight: lighter;
  line-height: 20px;
  margin-bottom: 20px;
  color: #0a2342;
}

.swipe-init {
  transition: all 400ms ease-out;
  clip-path: inset(0% 100% 0% 0%);
}

.swipe-out {
  clip-path: inset(0% 0% 0% 100%) !important;
}

.swipe-reveal {
  clip-path: inset(0% 0% 0% 0%);
}

.fade-init {
  transition: opacity 200ms ease-in-out;
}

.thumb-gallery {
  background-image: none;
  cursor: pointer;
}

.thumb-gallery:hover .enlarge-button {
  background-color: #fff;
}

.gallery-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.plan-viewer {
  overflow: hidden;
}

.custom-hotspot {
  width: 60px;
  height: 60px;
  left: -30px;
  /* background-color: #da3d36; */
  /* border-radius: 100px; */
  background-image: url("../images/floor-image-icon.png");
  background-size: contain;
}

.custom-hotspot:hover {
  background-image: url("../images/floor-image-icon-hover.png");
}

.video-player:hover .video-play-button {
  background-image: url("../images/video-play-button-hover.svg");
}

.video-viewer {
  width: 100%;
  height: 100%;
  background-color: transparent;
  margin-right: 0px;
}

.slide-close-container {
  z-index: 2500;
}

.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}

.video-full {
  width: 100%;
  height: 100%;
}

.fifty-nav-logo {
  transition: opacity 400ms ease-in-out;
}

.feature-slide-main {
  background-color: transparent;
}

.slide-bg {
  opacity: 0.9;
  transition: opacity 400ms ease-in-out;
}

.contact-left-col,
.contact-right-col {
  opacity: 1;
  transform: translateX(0px);
  transition: all 400ms ease-in-out;
}

.contact-intro {
  opacity: 0;
  transform: translateX(-20px);
}

.contact-outro {
  opacity: 0;
}

.burger-bg {
  clip-path: inset(0% 0% 100% 0%);
  transition: all 200ms ease-out;
}

.burger-bg.burger-open {
  clip-path: inset(0% 0% 0% 0%);
}

.burger-fifty-lines {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 400ms ease-out;
}

.burger-fifty-lines.burger-open {
  opacity: 1;
  transform: translateX(0px);
}

.burger-wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}

.burger-wrapper.burger-open {
  overflow-y: scroll !important;
}

.fifty-address {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.fifty-address.burger-open {
  opacity: 1;
}

.burger-item {
  transform: translateY(-20px);
  opacity: 0;
  transition: all 400ms ease-out;
}

.burger-item.burger-open {
  transform: translateY(0px);
  opacity: 1;
}

.fifty-nav-logo.burger-open {
  opacity: 0;
}

.nav-item.burger-active .nav-item-line {
  background-color: white;
}

.intro-header-text {
  transform: translateY(0px);
  opacity: 1;
  transition: all 600ms ease-out;
}

.intro-header-text.intro-ready {
  opacity: 0;
  transform: translateY(50px);
}

.intro-button {
  transition: all 400ms ease-out;
  clip-path: inset(0% 0% 0% 0%);
}

.intro-button.intro-ready {
  clip-path: inset(0% 100% 0% 0%);
}

.intro-slide-bg {
  transition: all 600ms ease-out;
  transform: translateX(0px);
  clip-path: inset(0% 0% 0% 0%);
}

.intro-slide-bg.intro-ready {
  transform: translateX(-50px);
  clip-path: inset(0% 100% 0% 0%);
}

.intro-slide {
  transition: all 600ms ease-in;
  clip-path: inset(0% 0% 0% 0%);
}

.intro-slide.outro {
  clip-path: inset(0% 0% 0% 100%);
}

.gallery-image {
  transition: all 200ms ease-in-out;
}

.dl-link {
  text-decoration: none;
  color: inherit;
}

.instruction {
  align-self: flex-start !important;
}

.intro-video-preroll {
  min-height: 100vh;
  @media (min-width: 2200px) {
    height: auto;
    width: 100vw;
    
  }
}

.video-thumb-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.video-thumb-wrapper video {
  position: absolute;
  left: 0px;
  top: 0px;
  /* width: 100%; */
  height: 100%;
}

.video-thumb-wrapper:hover .video-play-button {
  background-image: url("../images/video-play-button-hover.svg");
}

.overlay-main-container {
  overflow-x: hidden;
}

.floor-let {
  opacity: 0.5;
  cursor: default;
}

.landing-page-play-button:hover {
  color: #ffcf40;
}

.landing-page-play-button:hover .video-play-button {
  background-image: url("../images/video-play-button-hover.svg");
}

.swiper-slide-ext-gal img,
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide-ext-gal {
  opacity: 0.5;
  transition: opacity 400ms ease-in-out;
}

.swiper-slide-ext-gal.swiper-active-class {
  opacity: 1;
}

.swiper-main-gallery {
  /* overflow: visible; */
  height: 83vh !important;
}

.swiper-main-wrapper {
  height: 80vh;
}

.landing-galllery-controls {
  bottom: 0px;
  z-index: 200;
}

.gallery-thumb-list {
  width: auto !important;
}

.floor-title-area {
  padding-left: 0px;
}

.block-display {
  display: block;
}

@media screen and (max-width: 991px) {
  .feature-smart-img {
    position: relative;
    height: 450px;
    width: 100%;
  }

  .app,
  .interface {
    position: static !important;
  }

  .body {
    height: auto;
    overflow-x: hidden;
  }

  .content-fade {
    width: 80px;
  }
  .content-top-fade {
    height: 80px;
  }
  .content-right-fade {
    width: 60px;
  }
  .slide-placer {
    pointer-events: none !important;
  }
}

@media screen and (max-height: 699px) and (min-width: 991px) {
  .slide-popout {
    min-height: auto;
    height: 300px;
  }

  .copy {
    font-size: 16px;
    line-height: 22px;
  }
  .panel-padding-container {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 568px) {
  .landing-gallery-thumbs {
    display: none !important;
  }
}
